.loader {
  border: 8px solid #000;
  border-top: 8px solid #D5FF24;
  border-radius: 50%;
  width: 82px;
  height: 82px;
  top: 150px;
  left: calc(50% - 41px);
  animation: spin 1s linear infinite;
  position: absolute;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
