@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-montreal;
  }

  #root {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
}
